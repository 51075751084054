import React from "react";
import Layout from "../components/layout";
import topografia1 from "../images/topografia1.jpeg";
import topografia3 from "../images/topografia3.jpeg";
import SEO from "../components/seo";

function Topografia() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Orçamento"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${topografia1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Topografia
        </h1>
      </section>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-2 xl:m-12">
        <div>
          <h3>
          Nossos serviços de topografia empregam tecnologia avançada para mapear e analisar o terreno com precisão. 
          Utilizamos instrumentos de última geração para medir distâncias, ângulos e elevações, gerando mapas detalhados. 
          Essas informações são fundamentais para projetos de construção, planejamento urbano e demarcação de terrenos. 
          Garantimos resultados confiáveis para orientar decisões estratégicas em suas empreitadas.
          </h3>
        </div>
        <img src={topografia3} />
      </div>
    </Layout>
  );
}

export default Topografia;
